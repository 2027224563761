import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const NxBreadcrumb = (props) => {
  const { breadcrumb, pathname, index, is_number, reference } = { ...props };
  const [isLoaded, setIsLoaded] = useState(false);
  const [label, setLabel] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (is_number) {
        try {
          if (
            global.UF.dataProvider.referenceProvider.reference_configs.hasOwnProperty(
              reference
            )
          ) {
            const label =
              await global.UF.dataProvider.referenceProvider.getReferenceLabel_v2(
                reference,
                breadcrumb
              );
            setLabel(label);
          }
          setIsLoaded(true);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        setIsLoaded(true);
      }
    };

    fetchData();
  }, []);

  const handleBreadcrumbClick = () => {
    const split = pathname.split("/").filter((breadcrumb) => breadcrumb !== "");
    split.length = index + 1;
    const url = split.join("/");
    navigate(url);
  };

  const handleBreadcrumbLabel = (breadcrumb) => {
    if (label && label.length) {
      return label;
    }
    if (breadcrumb.includes("-")) {
      breadcrumb = breadcrumb.split("-");
      breadcrumb = breadcrumb.map((b) => global.UF.capitalizeFirstLetter(b));
      breadcrumb = breadcrumb.join(" ");
      return breadcrumb;
    }
    return global.UF.capitalizeFirstLetter(breadcrumb);
  };

  if (!isLoaded) {
    <div>Loading...</div>;
  }

  return (
    <Chip
      style={{ cursor: "pointer", userSelect: "none" }}
      onClick={handleBreadcrumbClick}
      label={handleBreadcrumbLabel(breadcrumb)}
    />
  );
};

export const NxBreadcrumbs = (props) => {
  const pathname = props?.router?.location?.pathname;

  const breadcrumbs = pathname.split("/").filter((breadcrumb) => {
    return breadcrumb !== "";
  });

  const renderBreadcrumbs = () => {
    return breadcrumbs.map((breadcrumb, index) => {
      const is_number = !isNaN(parseInt(breadcrumb));
      return (
        <div key={index}>
          <NxBreadcrumb
            breadcrumbs={breadcrumbs}
            pathname={pathname}
            index={index}
            breadcrumb={breadcrumb}
            is_number={is_number}
            reference={is_number ? breadcrumbs[index - 1] : null}
          />
        </div>
      );
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "start",
        marginLeft: "50px",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Breadcrumbs separator=">">{renderBreadcrumbs()}</Breadcrumbs>
    </div>
  );
};
