import React from "react";
import PropTypes from "prop-types";
import NxDialog from "../../ui_components/dialogs/NxDialog";
import { Typography } from "@mui/material";
import Grid from "../../ui_components/layouts/Grid/Grid";

function DeleteItemModal({ open, onClose, onDelete, text }) {
  const buttons = [
    {
      label: "Cancel",
      onClick: onClose,
      variant: "text",
    },
    {
      label: "Delete",
      onClick: onDelete,
      variant: "text",
    },
  ];
  return (
    <NxDialog open={open} handleClose={onClose} buttons={buttons} width={400}>
      <Grid style={{ placeContent: "center", textAlign: "center" }}>
        <Typography variant="h6">{text}</Typography>
      </Grid>
    </NxDialog>
  );
}

DeleteItemModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default DeleteItemModal;
