import React from "react";
import NxIconButton from "./NxIconButton";
import DownloadIcon from "@mui/icons-material/Download";
import "./scss/controls.scss";

export default function NxCtrlDownload({ tooltip, path, file_name }) {
  const handleDownload = () => {
    const url = `${window.location.origin}/api/${path}`;
    const link = document.createElement("a");
    link.href = url;
    link.download = file_name ? file_name : "file";
    link.target = "_blank";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <NxIconButton
      link={true}
      tooltip={tooltip ? tooltip : "Download File"}
      onClick={handleDownload}
    >
      <DownloadIcon />
    </NxIconButton>
  );
}
