import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import "./scss/controls.scss";

function NxIconButton(props) {
  const style = props.style ? props.style : {};
  const { tooltip } = props;

  const onClick = (event) => {
    event.ignore = true;
    if (props.link) {
      props.onClick();
      return;
    }
    if (props.onClick) {
      props.onClick();
    }
  };

  const renderElement = () => {
    if (tooltip && tooltip.length) {
      return (
        <Tooltip title={tooltip} style={{ zIndex: "10" }}>
          <IconButton
            size={props.size ? props.size : "small"}
            style={style}
            onClick={onClick}
          >
            {props.children}
          </IconButton>
        </Tooltip>
      );
    }
    return (
      <IconButton
        size={props.size ? props.size : "small"}
        style={style}
        onClick={onClick}
      >
        {props.children}
      </IconButton>
    );
  };

  return renderElement();
}

export default NxIconButton;
