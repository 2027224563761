import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button/Button";
import "./scss/controls.scss";
import { Tooltip } from "@mui/material";

function NxButton(props) {
  return (
    <Tooltip
      title={props.tooltip ? props.tooltip : props.label ? props.label : ""}
    >
      <Button
        disabled={props.disabled}
        variant={props.variant}
        size={props.size}
        onClick={props.onClick ? () => props.onClick() : () => {}}
        type={props.type}
        style={props.style}
        className={`${
          props.variant === "contained"
            ? "nx_button_contained"
            : "nx_button_outlined"
        } ${props.className}`}
      >
        {props.children ? props.children : <span>{props.label}</span>}
      </Button>
    </Tooltip>
  );
}

NxButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.oneOf(["text", "contained", "outlined"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  style: PropTypes.object,
};

NxButton.defaultProps = {
  className: "",
  variant: "text",
  size: "medium",
};

export default NxButton;
