import React, { Fragment } from "react";
import NxContent from "../../../nix_components/components/layouts/NxContent";

export default class Registrations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordItem: null,
      loaded: false,
    };
  }

  gridProps = {
    fields: [
      "first_name",
      "last_name",
      "email",
      "phone_number",
      "referral_url",
      "ip_address",
      "created_at",
    ],
  };

  render() {
    return (
      <Fragment>
        {this.state.passwordItem && this.renderSetPasswordModal()}
        <NxContent
          table="registrations"
          title="Registrations"
          gridProps={this.gridProps}
          onSave={this.onSave}
          buttons={this.buttons}
          export={true}
          delete={true}
          formConfigAdd={this.formConfigAdd}
          formConfig={this.formConfigEdit}
        />
      </Fragment>
    );
  }
}
