import React, { Fragment } from "react";
import NxContent from "../../../nix_components/components/layouts/NxContent";
import PasswordIcon from "@mui/icons-material/Password";
import SetPasswordModal from "../../../nix_components/components/modals/SetPasswordModal";
import NxIconButton from "../../../nix_components/ui_components/controls/NxIconButton";

export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordItem: null,
      loaded: false,
    };
  }

  buttons = {
    setPassword: (params) => {
      return (
        <NxIconButton
          tooltip="Set Password"
          aria-label="edit"
          color="default"
          onClick={() => this.setState({ passwordItem: params.row })}
        >
          <PasswordIcon />
        </NxIconButton>
      );
    },
  };

  formConfigAdd = {
    rows: [
      ["first_name", "last_name"],
      ["email", "password"],
    ],
  };

  formConfigEdit = {
    rows: [["first_name", "last_name", "email"]],
  };

  gridProps = {
    fields: ["first_name", "last_name", "email"],
  };

  setPassword = (password, user) => {
    global.UF.makeRequest(
      `POST`,
      `/api/set-password`,
      { id: user.id, password: password },
      true,
      (success) => {
        this.setState({ passwordItem: null });
      },
      (error) => {
        if (error) {
          console.error(error);
        }
      }
    );
  };

  renderSetPasswordModal = () => {
    return (
      <SetPasswordModal
        open={this.state.passwordItem !== null}
        onClose={() => this.setState({ passwordItem: null })}
        onSetPassword={(password) =>
          this.setPassword(password, this.state.passwordItem)
        }
      />
    );
  };

  onSave = (dataItem, callback) => {
    dataItem.email = dataItem.email.trim().toLowerCase();
    dataItem.Save(async (response) => {
      if (response?.hasOwnProperty("error")) {
        global.UF.setAlertVisibility(true, response.error, "error");
        return;
      }

      if (dataItem.isNew && response.hasOwnProperty("id") && response.id) {
        const id = response.id;
        try {
          await global.UF.makeRequest_v2(
            `POST`,
            `/api/set-password`,
            { id: id, password: dataItem.password },
            true
          );

          if (callback) {
            callback();
          }
        } catch (err) {
          console.error(err);
          global.UF.setAlertVisibility(true, err.toString(), "error");
        }
      } else {
        if (callback) {
          callback();
        }
      }
    });
  };

  render() {
    return (
      <Fragment>
        {this.state.passwordItem && this.renderSetPasswordModal()}
        <NxContent
          table="users"
          title="Users"
          gridProps={this.gridProps}
          onSave={this.onSave}
          buttons={this.buttons}
          edit={true}
          export={false}
          delete={true}
          formConfigAdd={this.formConfigAdd}
          formConfig={this.formConfigEdit}
          add={true}
          sync={false}
        />
      </Fragment>
    );
  }
}
