import React, { useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import "../layouts/scss/nx_grid.scss";

const NxLoaderRequests = () => {
  const [show, setShow] = useState(false);

  const setLoader = (show) => {
    setShow(show);
  };

  global.loadingSetVisibility = setLoader;

  if (!show) return null;

  return (
    <Backdrop open={show} className="loader" color="#fff">
      <CircularProgress color={"primary"} />
    </Backdrop>
  );
};

export default NxLoaderRequests;
