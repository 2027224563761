import React from "react";
import PropTypes from "prop-types";
import "./scss/controls.scss";
import TextField from "@mui/material/TextField";
import DataTypes from "../../data/DataTypes";

class NxCtrlTextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.inputStyle = this.props.inputStyle
      ? this.props.inputStyle
      : { fontSize: this.props.fontSize };
    this.inputLabelStyle = this.props.inputLabelStyle
      ? this.props.inputLabelStyle
      : { fontSize: this.props.fontLabelSize };
  }

  onChange = (event) => {
    let value = event.target.value;
    if (!value) {
      this.props.onChange(null);
      return;
    }

    if (this.props.type === DataTypes.number) {
      this.handleNumberOnChange(value);
      return;
    }

    this.props.onChange(value);
  };

  handleNumberOnChange = (value) => {
    if (
      this.props.type === DataTypes.number &&
      this.props.fieldConfig.hasOwnProperty("max")
    ) {
      if (parseInt(value) <= this.props.fieldConfig.max) {
        this.props.onChange(value);
        return;
      } else {
        this.props.onChange(this.props.fieldConfig.max);
        return;
      }
    }
    if (
      this.props.type === DataTypes.number &&
      this.props.fieldConfig.hasOwnProperty("min")
    ) {
      if (parseInt(value) >= this.props.fieldConfig.min) {
        this.props.onChange(value);
        return;
      } else {
        this.props.onChange(this.props.fieldConfig.min);
        return;
      }
    }
    this.props.onChange(value);
  };

  renderHelperText = () => {
    if (this.props.fieldConfig && this.props.fieldConfig.helperText) {
      return this.props.fieldConfig.helperText;
    }
    if (this.props.helperText && this.props.helperText.length) {
      return this.props.helperText;
    } else {
      if (this.props.fieldConfig?.datatype === "number") {
        return "This field only accept numbers";
      }
      return "";
    }
  };

  render() {
    return (
      <TextField
        style={this.props.style}
        label={
          this.props.fieldConfig
            ? this.props.fieldConfig.label
            : this.props.label
        }
        value={
          this.props.value != null || this.props.value != undefined
            ? this.props.value
            : ""
        }
        placeholder={this.props.placeholder}
        variant={this.props.variant}
        disabled={
          this.props.fieldConfig
            ? this.props.fieldConfig.disabled
            : this.props.disabled
        }
        error={this.props.error}
        autoFocus={this.props.autoFocus}
        fullWidth={this.props.fullWidth}
        helperText={this.renderHelperText()}
        type={this.props.type}
        inputProps={{
          min: this.props.fieldConfig
            ? this.props.fieldConfig.min
            : this.props.min,
          max: this.props.fieldConfig
            ? this.props.fieldConfig.max
            : this.props.max,
        }}
        InputLabelProps={{ style: this.inputLabelStyle }}
        onKeyDown={this.props.onKeyDown}
        onChange={this.onChange}
        multiline={
          this.props.fieldConfig
            ? this.props.fieldConfig.multiline
            : this.props.multiline
        }
        rows={
          this.props.fieldConfig
            ? this.props.fieldConfig.multiline
              ? 5
              : null
            : null
        }
        size={this.props.size}
        className={` nx_textfield ${
          this.props.fieldConfig?.multiline ? " nx_textfield_multiline " : ""
        } ${this.props.className ? this.props.className : ""} ${
          this.props.error ? " nx_error " : ""
        }`}
      />
    );
  }
}

NxCtrlTextInput.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(["text", "password", "number", "color", "file"]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  fontSize: PropTypes.string,
  fontLabelSize: PropTypes.string,
  multiline: PropTypes.bool,
  size: PropTypes.oneOf(["medium", "small", "string"]),
};

NxCtrlTextInput.defaultProps = {
  className: "",
  disabled: false,
  fullWidth: true,
  autoFocus: false,
  error: false,
  helperText: "",
  type: "text",
  fontSize: "12px",
  fontLabelSize: "11px",
  multiline: false,
  size: "small",
};

export default NxCtrlTextInput;
