import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import Menu from "../../nix_components/components/layouts/Menu/Menu.jsx";
import Grid from "../../nix_components/ui_components/layouts/Grid/Grid.js";
import NxLoader from "../../nix_components/components/utils/NxLoader.jsx";
import { NxBreadcrumbs } from "../../nix_components/components/utils/NxBreadcrumbs.jsx";
import NxButton from "../../nix_components/ui_components/controls/NxButton.jsx";
import withRouter from "../../nix_components/components/utils/withRouter.js";
import Users from "./Users/Users.jsx";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { ArrowBack, Group, HowToReg } from "@mui/icons-material";
import Registrations from "./Registrations/Registrations.jsx";

class Secure extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: true,
      loaded: false,
      initializing: true,
    };

    this.menuItems = [
      {
        title: "Users",
        level: 1,
        url: "/users",
        icon: <Group />,
        element: () => {
          return <Users />;
        },
      },
      {
        title: "Registrations",
        level: 1,
        url: "/registrations",
        icon: <HowToReg />,
        element: () => {
          return <Registrations />;
        },
      },
    ];
  }

  componentDidMount = async () => {
    this.setState({ loaded: true });
  };

  onLogOut = () => {
    localStorage.clear("token");
    localStorage.clear("user");
    window.location.href = "/";
  };

  handleMenu = () => {
    const showMenu = !this.state.showMenu;
    this.setState({ showMenu });
  };

  handleBack = () => {
    if (this.props.router.location.key !== "default") {
      this.props.router.navigate(-1);
    }
  };

  renderElement = () => {
    this.routes = this.renderRoutes();
    this.routes.unshift(<Route path="*" element={<Users />} />);
    return (
      <Grid
        fullScreen={true}
        style={{
          backgroundColor: "#F8FAFB",
          gridTemplateColumns: `${this.state.showMenu ? "321px auto" : "auto"}`,
          overflow: "hidden",
          minHeight: "925px",
        }}
      >
        {/* <IconButton
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            zIndex: "1000",
          }}
          onClick={this.handleMenu}
        >
          {this.state.showMenu ? (
            <ArrowBack
              style={{
                color: `${
                  this.state.showMenu ? "#F8FAFB" : "rgba(30, 30, 30, 0.70)"
                }`,
              }}
            />
          ) : (
            <MenuIcon
              style={{
                color: `${
                  this.state.showMenu ? "#F8FAFB" : "rgba(30, 30, 30, 0.70)"
                }`,
              }}
            />
          )}
        </IconButton> */}
        <Grid
          style={{
            width: `${this.state.showMenu ? "321px" : "0px"}`,
            transition: `width 1s ease, opacity 1s ease`,
            backgroundColor: "rgba(30, 30, 30, 0.70)",
            overflow: "hidden",
            overflowY: "auto",
            opacity: `${this.state.showMenu ? "1" : "0"}`,
          }}
          hidden={!this.state.showMenu}
        >
          <Menu items={this.menuItems} />
        </Grid>
        <Grid style={{ gridTemplateRows: "80px auto" }}>
          <Grid>
            <NxBreadcrumbs router={this.props.router} />
            <NxButton
              style={{
                width: 100,
                height: 50,
                position: "absolute",
                top: "50%",
                right: 20,
                transform: "translate(0, -50%)",
              }}
              onClick={() => this.onLogOut()}
              label={"Log out"}
              variant={"text"}
            />
          </Grid>
          <Grid className={"uf_content_box"}>
            <Routes>{this.routes}</Routes>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderRoutes = () => {
    if (this.menuItems && this.menuItems.length) {
      return this.menuItems.map((menuItem, index) => {
        if (menuItem.hasOwnProperty("children")) {
          return menuItem.children.map((child, key) => {
            let currentMenuItem = menuItem.children.find(
              (item) => item.url === child.url
            );
            return (
              <Route
                path={`/${currentMenuItem.url.split("/")[2]}`}
                key={`${key}`}
                element={currentMenuItem.element()}
              />
            );
          });
        } else {
          let currentMenuItem = this.menuItems.find(
            (item) => item.url === menuItem.url
          );
          const split = currentMenuItem.url.split("/");
          let tempUrl = "";
          if (split.length > 2) {
            split.slice(0, 1);
            tempUrl = split.join("/");
          } else {
            tempUrl = split[1];
          }
          return (
            <Route
              path={`/${tempUrl}`}
              key={`/${index}`}
              element={currentMenuItem.element()}
            />
          );
        }
      });
    } else return null;
  };

  render() {
    if (!this.state.loaded) {
      return <NxLoader show={!this.state.loaded} />;
    } else {
      return <Fragment>{this.renderElement()}</Fragment>;
    }
  }
}

export default withRouter(Secure);
