import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";

export default function NxCtrlTimepicker(props) {
  const onChange = (event) => {
    if (event && event.$d) {
      if (event.$d != "Invalid Date") {
        let date = new Date(event.$d);
        props.onChange(date);
      }
    } else {
      props.onChange(null);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label={props.fieldConfig.label}
        value={props.value ? props.value : null}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            size={"small"}
            {...params}
            error={props.error}
            helperText={props.helperText}
            sx={{ width: "100%" }}
          />
        )}
        ampm={false}
        className={"nx_textfield"}
      />
    </LocalizationProvider>
  );
}
