import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  FormHelperText,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Typography,
} from "@mui/material";
import "./scss/controls.scss";

const NxCtrlSelect = ({
  dataItem,
  fieldConfig,
  onChange,
  data,
  value,
  error,
  helperText,
  read_only,
  width,
}) => {
  let [items, setItems] = React.useState([]);

  if (
    fieldConfig &&
    fieldConfig.hasOwnProperty("reference_groupby") &&
    dataItem[fieldConfig.reference_groupby]
  ) {
    items = items.filter((item) => {
      if (item.hasOwnProperty("group")) {
        return item.group == dataItem[fieldConfig.reference_groupby];
      } else {
        return true;
      }
    });
  }

  if (fieldConfig && fieldConfig.hasOwnProperty("reference_filter")) {
    items = fieldConfig.reference_filter(items, dataItem);
  }

  useEffect(() => {
    if (fieldConfig.reference) {
      global.UF.dataProvider.referenceProvider.get(
        fieldConfig.reference,
        (retrievedItems) => {
          retrievedItems = retrievedItems.sort((a, b) =>
            a.label.localeCompare(b.label)
          );
          retrievedItems = retrievedItems.filter(
            (item) => item.label && item.label.length
          );
          const temp_items = arrOfItems(retrievedItems);
          setItems(temp_items);
        }
      );
    }
  }, [fieldConfig?.reference]);

  useEffect(() => {
    if (data !== null) {
      const temp_data = arrOfItems(data);
      setItems(temp_data);
    }
  }, [data]);

  const onValueChange = (eValue) => {
    if (fieldConfig?.multiple) {
      if (eValue[eValue.length - 1] == "*") {
        eValue = value
          ? value.length === items.length
            ? []
            : items.map((item) => item.id)
          : items.map((item) => item.id);
      }
    }

    if (dataItem) {
      dataItem[fieldConfig.name] = eValue;
    }
    if (onChange) {
      if (dataItem) {
        onChange(dataItem[fieldConfig?.name]);
      } else {
        onChange(eValue);
      }
    }
  };

  const arrOfItems = (items) => {
    let temp_arr = [];

    if (!items || !items.length) {
      return temp_arr;
    }

    if (fieldConfig?.reference) {
      temp_arr = items;
    }

    if (Array.isArray(items) && !fieldConfig?.reference) {
      temp_arr = items.map((item) => {
        if (item.hasOwnProperty("id") && item.hasOwnProperty("label")) {
          return { id: item.id, label: item.label };
        } else {
          return { id: item, label: item };
        }
      });
    }

    if (typeof items === "object" && !Array.isArray(items)) {
      temp_arr = Object.keys(items).map((key) => {
        return { id: key, label: items[key] };
      });
    }

    return temp_arr;
  };

  const renderMenuItems = () => {
    if (!items || !items.length) {
      return (
        <MenuItem key={"No options"} value={"No options"} disabled={true}>
          No available options
        </MenuItem>
      );
    }

    if (fieldConfig?.multiple) {
      return items.map((item) => {
        if (!item) {
          return null;
        }

        return (
          <MenuItem key={item.id} value={item.id}>
            <ListItemIcon>
              <Checkbox checked={value?.indexOf(item.id) > -1} />
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </MenuItem>
        );
      });
    }

    return items.map((item) => {
      if (!item) {
        return null;
      }
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.label}
        </MenuItem>
      );
    });
  };

  const formated_value =
    fieldConfig && fieldConfig.multiple
      ? Array.isArray(value)
        ? value
        : value != undefined && value != null && value != "null"
        ? JSON.parse(value)
        : []
      : value !== undefined && value !== null
      ? typeof value == "string"
        ? value.includes('"', 0)
          ? JSON.parse(value)
          : value
        : value
      : "";

  return (
    <>
      <FormControl
        style={{ width }}
        sx={
          fieldConfig && fieldConfig.width
            ? { width: fieldConfig.width }
            : { width: "100%" }
        }
        className={`nx_textfield nx_selectfield ${error ? " nx_error" : ""}`}
      >
        <InputLabel>
          {fieldConfig && fieldConfig.label
            ? fieldConfig.label
            : fieldConfig?.name}
        </InputLabel>
        <Select
          MenuProps={{
            variant: "menu",
          }}
          variant={
            fieldConfig && fieldConfig.variant
              ? fieldConfig.variant
              : "outlined"
          }
          disabled={read_only ? read_only : fieldConfig && fieldConfig.disabled}
          size={fieldConfig && fieldConfig.size ? fieldConfig.size : "small"}
          label={
            fieldConfig && fieldConfig.label
              ? fieldConfig.label
              : fieldConfig?.name
          }
          multiple={
            fieldConfig && fieldConfig.multiple ? fieldConfig.multiple : false
          }
          inputProps={{ readOnly: read_only }}
          value={formated_value}
          error={error}
          onChange={(e) => onValueChange(e.target.value)}
          renderValue={(value) => {
            if (!Array.isArray(value)) {
              value = [value];
            }
            return value
              .map((item) => items.find((i) => i.id == item)?.label)
              .join(", ");
          }}
        >
          {fieldConfig?.multiple && items && items.length ? (
            <MenuItem value={"*"} key={"*"}>
              <ListItemIcon>
                <Checkbox
                  checked={
                    items &&
                    items.length > 0 &&
                    value &&
                    value.length === items.length
                  }
                  indeterminate={
                    value && value.length > 0 && value.length < items.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Select All
                  </Typography>
                }
                disableTypography
              />
            </MenuItem>
          ) : null}
          {renderMenuItems()}
        </Select>
        <span className="nx_select_helpertext">
          <FormHelperText>{helperText}</FormHelperText>
        </span>
      </FormControl>
    </>
  );
};

NxCtrlSelect.propTypes = {
  dataItem: PropTypes.object,
  fieldConfig: PropTypes.object,
};

export default NxCtrlSelect;
