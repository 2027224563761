import "../nx_form.scss";
import Grid from "../../../ui_components/layouts/Grid/Grid";

const NxForm = (props) => {
  const style = props.style ? props.style : {};
  return (
    <div className="uf_form" style={style}>
      {props.children}
    </div>
  );
};

const NxFormRow = (props) => {
  return (
    <Grid
      className={`uf_form_row ${props.alignment ? props.alignment : ""}`}
      style={{
        gridTemplateColumns: "repeat(" + props.children.length + ", 1fr)",
      }}
    >
      {props.children}
    </Grid>
  );
};
const NxFormCell = (props) => {
  return <Grid>{props.children}</Grid>;
};

export { NxForm, NxFormRow, NxFormCell };
