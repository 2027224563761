const referenceTypes = {
  local: "local",
  remote: "remote",
};

export const referenceConfigs = {
  view_domains: {
    id: "referral_url",
    label: "referral_url",
    type: referenceTypes.remote,
    table: "view_domains",
  },
};
