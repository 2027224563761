import uuid from "react-uuid";
import { validators } from "./DataValidators";
import DataTypes from "./DataTypes";

export const static_configs = {
  users: {
    ignoreValidation: ["id", "created_at"],
    fields: {
      id: {
        hide: true,
      },
      first_name: {
        field: "first_name",
        label: "First name",
        validators: [
          validators.isNull,
          validators.isEmpty,
          validators.hasSpecialCharAsFirstChar,
        ],
      },
      last_name: {
        field: "last_name",
        label: "Last name",
        validators: [
          validators.isNull,
          validators.isEmpty,
          validators.hasSpecialCharAsFirstChar,
        ],
      },
      email: {
        field: "email",
        label: "Email",
        validators: [
          validators.isNull,
          validators.isEmpty,
          validators.isNotEmail,
        ],
      },
      email_password: {
        field: "email_password",
        label: "Email Password",
      },
      password: {
        field: "password",
        label: "Password",
        validators: [
          validators.isNull,
          validators.isEmpty,
          validators.isNotValidPassword,
        ],
      },
      status: {
        field: "status",
        label: "Status",
        defaultValue: "Active",
      },
      age: {
        field: "age",
        label: "Age",
      },
      user_guid: {
        field: "user_guid",
        label: "User Guid",
        defaultValue: uuid(),
      },
    },
    errorHandling: (requestType, error, dataItem) => {
      const errorMsg = error.error;
      if (errorMsg.includes("users.email_UNIQUE")) {
        return {
          code: error.code,
          error: "User with that email already exists!",
        };
      }
      return error;
    },
  },
  registrations: {
    sorting: [{ field: "created_at", sort: "desc" }],
    fields: {
      first_name: {
        field: "first_name",
        label: "First Name",
      },
      last_name: {
        field: "last_name",
        label: "Last Name",
      },
      email: {
        field: "email",
        label: "Email",
      },
      phone_number: {
        field: "phone_number",
        lable: "Phone Number",
      },
      raw_json: {
        field: "raw_json",
        label: "JSON",
        datatype: DataTypes.json,
      },
      referral_url: {
        field: "referral_url",
        label: "Referral Url",
        datatype: DataTypes.reference,
        reference: "view_domains",
        filterable: true,
        href: (dataItem) => {
          return `${dataItem.referral_url}`;
        },
      },
      ip_address: {
        field: "ip_address",
        label: "IP Address",
      },
    },
  },
};
