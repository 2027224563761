import * as React from "react";
import { Box, List } from "@mui/material";
import ListItemDropDown from "./controls/ListItemDropDown";
import ListItem from "./controls/ListItem";
import withRouter from "../../utils/withRouter";
import { Fragment } from "react";
import Grid from "../../../ui_components/layouts/Grid/Grid";

function Menu(props) {
  const handleActiveItem = (item) => {
    if (item.hasOwnProperty("url")) {
      if (item.url.includes("..")) {
        item.active =
          item.url.split("..")[1] === props.router.location.pathname;
      } else {
        const url = item.url.split("/")[1];
        const pathname = props.router.location.pathname.split("/")[1];
        item.active = pathname == url;
      }
    } else {
      if (item.hasOwnProperty("children")) {
        item.children.forEach((child) => {
          if (child.hasOwnProperty("url")) {
            child.active =
              child.url.split("..")[1] === props.router.location.pathname;
            item.active = child.active === true;
          }
        });
        item.active = item.children.findIndex((child) => child.active) > -1;
        item.open = item.active;
      } else {
        item.active = false;
      }
    }
  };

  const removeTrailingSlash = (str) => {
    if (str.charAt(str.length - 1) === "/") {
      return str.slice(0, -1); // Remove the last character
    }
    return str; // Return the original string if no trailing slash
  };
  const navigate = (url) => {
    const split = url.split(":");
    let tempUrl = url;
    if (split.length > 1) {
      tempUrl = split[0];
    }
    tempUrl = removeTrailingSlash(tempUrl);
    props.router.navigate(`${tempUrl}`);
  };

  const renderMenuItems = () => {
    return (
      <Box sx={{ width: 200 }} role="presentation">
        <List>
          {props.items && props.items.length
            ? props.items.map((item, index) => {
                handleActiveItem(item);
                return (
                  <Fragment key={index}>
                    {item.children && item.children.length ? (
                      <ListItemDropDown
                        children={item.children}
                        active={item.active}
                        open={item.open}
                        icon={item.icon}
                        title={item.title}
                      />
                    ) : (
                      <ListItem
                        title={item.title}
                        active={item.active}
                        icon={item.icon}
                        onClick={() => navigate(item.url)}
                      />
                    )}
                  </Fragment>
                );
              })
            : null}
        </List>
      </Box>
    );
  };

  return (
    <Grid
      style={{
        gridAutoFlow: "row",
        gridTemplateRows: "auto",
        overflow: "hidden",
      }}
    >
      <Grid
        style={{
          justifyContent: "center",
          overflow: "hidden",
          overflowY: "auto",
          marginTop: "20px",
        }}
      >
        {renderMenuItems()}
      </Grid>
    </Grid>
  );
}

export default withRouter(Menu);
