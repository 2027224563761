import React, { Fragment } from "react";
import { TextField } from "@mui/material";
import "./scss/controls.scss";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import IconButton from "@mui/material/IconButton/IconButton";
import ImageIcon from "@mui/icons-material/Image";
import NxCtrlDownload from "./NxCtrlDownload";

class NxCtrlFileInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMsg: "",
      isError: false,
      tempPhoto: null,
      labelText: "",
    };
    this.files = [];
    this.totalSizeOfFiles = 0;
  }

  onChange = (event) => {
    this.files = [];
    this.state.isError = false;
    this.state.errorMsg = "";
    let files = event.target.files;
    if (!files || !files.length) {
      return;
    }
    this.state.labelText = files[0].name;
    Object.keys(files).forEach((key, index) => {
      let valid = this.extensionValidation(files[key]);
      this.sizeOfImageValidation(files[key], (validation) => {
        valid = valid && validation;
        if (valid) {
          this.files.push(files[key]);
        } else {
          this.forceUpdate();
        }
        if (index + 1 === Object.keys(files).length && valid) {
          this.props.onChange(this.files);
          this.forceUpdate();
        }
      });
    });
  };

  extensionValidation = (file) => {
    let extension = file.type.split("/")[1];
    let valid = this.props.accept.filter((a) => a.includes(extension)).length;
    if (!valid) {
      this.state.isError = true;
      this.state.errorMsg =
        "That extension is not valid and file won't be added";
      this.forceUpdate();
    }
    return valid;
  };

  sizeOfImageValidation = (file, callback) => {
    const requirements = this.props.requirements;
    if (requirements) {
      let _URL = window.URL || window.webkitURL;
      let img = new Image();
      let valid = false;
      let objectUrl = _URL.createObjectURL(file);
      img.src = objectUrl;
      img.onload = () => {
        if (
          img.naturalWidth >= requirements.width &&
          img.naturalHeight >= requirements.height
        ) {
          _URL.revokeObjectURL(objectUrl);
          valid = true;
        } else {
          this.state.isError = true;
          this.state.errorMsg = this.state.errorMsg.length
            ? this.state.errorMsg
            : `Minimum size of image is ${requirements.width}X${requirements.height}`;
          valid = false;
        }
        callback(valid);
      };
    } else {
      callback(true);
    }
  };

  sizeValidation = (file) => {
    let valid = this.totalSizeOfFiles + this.bytesToMegaBytes(file.size) < 25;
    if (!valid) {
      this.state.isError = true;
      this.state.errorMsg =
        "You cannot upload more then 25MB, so last file you added wont be uploaded";
    }
    this.forceUpdate();
    return valid;
  };

  bytesToMegaBytes = (bytes) => {
    return bytes / 1024 ** 2;
  };

  componentDidMount = () => {
    this.state.labelText = this.props.label;
    if (this.props.imagePreview) {
      this.state.tempPhoto = this.props.value;
    }
    this.forceUpdate();
  };

  render() {
    return (
      <Fragment>
        <div className="nx_ctrl_file_wrapper">
          <TextField
            className={`border_box invisible`}
            type={"file"}
            id={this.props.field}
            inputProps={{
              accept: this.props.accept ? this.props.accept.join(",") : ".png",
              className: "invisible",
            }}
            name={"fileToUpload"}
            onChange={this.onChange}
            helperText={
              this.props.helperText && this.props.helperText.length
                ? this.props.helperText
                : this.props.errorMsg
            }
            error={Boolean(this.props.error)}
          />
          <label
            htmlFor={this.props.field}
            style={{ padding: "10px" }}
            className={`border_box ${this.props.error ? "error" : ""}`}
          >
            {this.state.labelText}
          </label>
          {this.props.downloadFile ? (
            <InputAdornment position="start">
              <NxCtrlDownload
                tooltip={this.props.downloadFileTooltip}
                path={this.props.downloadFilePath}
                file_name={this.props.downloadFileName}
              />
            </InputAdornment>
          ) : null}
          {this.props.imagePreview &&
          this.state.tempPhoto !== null &&
          this.state.tempPhoto.length ? (
            <Fragment>
              <InputAdornment position="end" className="image_icon">
                <a
                  href={`${window.location.origin}/api/files/${this.state.tempPhoto}`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <IconButton>
                    <ImageIcon />
                  </IconButton>
                </a>
              </InputAdornment>
            </Fragment>
          ) : null}
          {this.state.errorMsg.length || this.props.error ? (
            <div className={"sc_error_msg"}>
              {this.props.error ? this.props.error : this.state.errorMsg}
            </div>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

export default NxCtrlFileInput;
