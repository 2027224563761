import React, { Component } from "react";
import "./scss/nx_grid.scss";
import DataTypes from "../../data/DataTypes";
import withRouter from "../utils/withRouter";
import Nx_Grid_Popover from "../modals/NxGridPopover";
import Grid from "../../ui_components/layouts/Grid/Grid";
import NxIconButton from "../../ui_components/controls/NxIconButton";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { DataGrid } from "@mui/x-data-grid";
import { Collapse, IconButton, Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import {
  KeyboardArrowRight,
  ExpandLess,
  ExpandMore,
  Edit,
  Notes,
} from "@mui/icons-material";
import moment from "moment";

class Nx_Grid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      columns: [],
      page: 0,
      pageSize: 10,
      totalItems: 0,
      isLoading: true,
      sortField: [],
      popoverActive: false,
      activeRows: [],
      rowHeight: 55.5,
      expandableRowId: -1,
    };
    this.fields = props.fields;
    this.config = props.config
      ? props.config
      : props.fields
      ? props.fields.map((field) =>
          typeof field == "string"
            ? global.UF.data_structure[props.table].fields[field]
            : field
        )
      : global.UF.data_structure[props.table].fieldsArr;

    this.lastUpdate = props.lastUpdate;

    this.references = {
      count: 0,
      loaded: 0,
      arr: [],
    };
  }

  capitalizeFirstLetter = (string) => {
    if (string.length) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };

  generateHeaderName = (item) => {
    if (item.label.includes("_")) {
      return `${this.capitalizeFirstLetter(
        item.label.split("_")[0]
      )} ${this.capitalizeFirstLetter(item.label.split("_")[1])}`;
    } else {
      return this.capitalizeFirstLetter(item.label);
    }
  };

  componentDidMount = () => {
    this.state.sortField = Array.isArray(
      global.UF.data_structure[this.props.table].sorting
    )
      ? global.UF.data_structure[this.props.table].sorting
      : global.UF.data_structure[this.props.table].fieldsArr.findIndex(
          (field) => field.Field === "created_at"
        ) > -1
      ? [
          {
            field: "created_at",
            sort: "desc",
          },
        ]
      : [];
    this.props.syncData(this.fetchData);
    this.fetchReferences(true);
  };

  fetchReferences = (force = false, filters = this.props.filters) => {
    if (this.references.count > 0) {
      this.references.loaded = 0;
      this.references.arr.forEach((ref) => {
        global.UF.dataProvider.referenceProvider.get(
          ref,
          (data) => {
            this.references[ref] = {};
            data.forEach(
              (item) => (this.references[ref][item.id] = item.label)
            );
            this.references.loaded++;
            if (this.references.loaded == this.references.count) {
              this.fetchData(filters);
            }
          },
          force
        );
      });
    } else {
      this.fetchData(filters);
    }
  };

  handleSortModelChange = (sortField) => {
    this.setState({ sortField }, this.fetchData);
  };

  renderButtons = (params) => {
    if (this.props.buttons) {
      return Object.keys(this.props.buttons).map((button, key) => {
        return (
          <span key={key}>
            {this.props.buttons[button](params, this.fetchData)}
          </span>
        );
      });
    } else {
      return null;
    }
  };

  handleToolTipText = (additional_data, row) => {
    return additional_data.map((field, key) => {
      if (row.hasOwnProperty(field)) {
        if (row[field] !== null && row[field] !== undefined) {
          return (
            <div key={key}>
              {this.capitalizeFirstLetter(field)} :{" "}
              {row[field].length > 0 ? row[field] : "Not defined"}
            </div>
          );
        } else {
          return (
            <div key={key}>
              {this.capitalizeFirstLetter(field)} : {"Not defined"}
            </div>
          );
        }
      }
    });
  };

  handleColumns = () => {
    const format = new Intl.NumberFormat("en-US").format;

    const format_decimal = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format;

    const columns = this.config.map((c, key) => {
      const config = {
        ...c,
        field: c.field,
        className: "something",
        headerAlign: "center",
        headerName: this.generateHeaderName(c),
        fontSize: "12px",
        flex: c.flex ? c.flex : 1,
        align: c.align ? c.align : null,
        width: c.width ? c.width : "auto",
      };

      if (!config["align"]) {
        switch (c.datatype) {
          case DataTypes.color:
          case DataTypes.number:
            config["align"] = "right";
            break;
          case DataTypes.date:
          case DataTypes.datetime:
          case DataTypes.boolean:
          case DataTypes.time:
          case DataTypes.reference:
            config["align"] = "center";
            break;
          default:
            config["align"] = "center";
        }
      }

      if (
        (c.datatype === DataTypes.reference ||
          c.datatype === DataTypes.autocomplete) &&
        !this.references.hasOwnProperty(c.reference)
      ) {
        this.references[c.reference] = null;
        this.references.count++;
        this.references.arr.push(c.reference);
      }

      if (!c.hasOwnProperty("renderCell")) {
        if (c.datatype === DataTypes.number) {
          config["renderCell"] = (params) => {
            const value = params.value;
            const is_decimal = value ? value % 1 > 0 : false;
            const formatted_value = value
              ? is_decimal
                ? format_decimal(value)
                : format(value)
              : "-";
            return (
              <Tooltip title={`${formatted_value}`} key={key}>
                <div>{formatted_value}</div>
              </Tooltip>
            );
          };
        }
        if (c.datatype === DataTypes.color) {
          config["renderCell"] = (params) => {
            const value = params.value;
            if (!value) {
              return (
                <Tooltip title={`-`} key={key}>
                  <div key={key}>-</div>
                </Tooltip>
              );
            }

            return (
              <Tooltip title={`${value}`} key={key}>
                <div
                  key={key}
                  style={{
                    backgroundColor: `${value}`,
                    width: "15px",
                    height: "15px",
                    borderRadius: "30%",
                  }}
                />
              </Tooltip>
            );
          };
        }

        if (
          c.datatype === DataTypes.time ||
          c.datatype === DataTypes.datetime
        ) {
          config["renderCell"] = (params) => {
            const value = params.value;
            if (!value) {
              return (
                <Tooltip key={key} title={"-"}>
                  <div>-</div>
                </Tooltip>
              );
            }

            const formatted_date = moment(value).format("YYYY/MM/DD");
            const formatted_time = moment(value).format("HH:mm:ss");
            const formatted_value = `${formatted_date} \n ${formatted_time}`;

            return (
              <Tooltip key={key} title={formatted_value}>
                <div style={{ whiteSpace: "pre-line", textAlign: "center" }}>
                  {formatted_value}
                </div>
              </Tooltip>
            );
          };
        }

        if (c.datatype === DataTypes.date) {
          config["renderCell"] = (params) => {
            if (params["value"] != null) {
              return (
                <Tooltip
                  key={key}
                  title={`${moment(params["value"]).format("YYYY/MM/DD")}`}
                >
                  <div style={{ whiteSpace: "pre-line" }}>
                    {`${moment(params["value"]).format("YYYY/MM/DD")}`}
                  </div>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip key={key} title={"-"}>
                  <div>-</div>
                </Tooltip>
              );
            }
          };
        }

        if (c.datatype === DataTypes.boolean) {
          config["renderCell"] = (params) => {
            const value = params.value;

            if (value == null) {
              return (
                <Tooltip key={key} title={"-"}>
                  <div>{"-"}</div>
                </Tooltip>
              );
            }

            const formatted_value = value === 1 ? "Yes" : "No";

            const colorYes = c.colorInverse ? "#EC5F5F" : "#82D73F";
            const colorNo = c.colorInverse ? "#82D73F" : "#EC5F5F";

            return (
              <Tooltip key={key} title={formatted_value}>
                <div
                  style={{
                    color: `${formatted_value === "Yes" ? colorYes : colorNo}`,
                  }}
                >
                  {formatted_value}
                </div>
              </Tooltip>
            );
          };
        }

        if (
          c.datatype === DataTypes.reference ||
          c.datatype === DataTypes.autocomplete
        ) {
          config["renderCell"] = (params) => {
            const value = params.value;
            const current_reference = this.references[c.reference];
            if (value == null) {
              return (
                <Tooltip key={key} title={"-"}>
                  <div>{"-"}</div>
                </Tooltip>
              );
            }

            return (
              <Tooltip
                key={key}
                title={value && current_reference && current_reference[value]}
                onClick={(event) => {
                  event.ignore = true;
                }}
              >
                {config.hasOwnProperty("href") ? (
                  <a
                    href={`${config.href(params.row)}`}
                    rel="noreferrer"
                    className="project_id_hover"
                    target="_blank"
                    onClick={(event) => {
                      event.ignore = true;
                    }}
                  >
                    {current_reference && current_reference[value]}
                  </a>
                ) : (
                  <div>
                    {value && current_reference && current_reference[value]}
                  </div>
                )}
              </Tooltip>
            );
          };
        }
      } else if (c.hasOwnProperty("renderCell")) {
        const tempRenderCell = c.renderCell;
        c.renderCell = (params) => {
          if (params.value != null) {
            if (typeof params.value == "string") {
              if (params.value && params.value.length) {
                return c.datatype == DataTypes.reference ||
                  c.datatype == DataTypes.autocomplete
                  ? tempRenderCell(params, this.references[c.reference])
                  : tempRenderCell(params, this.references[c.reference]);
              } else {
                return tempRenderCell(params, this.references[c.reference]);
              }
            } else {
              return tempRenderCell(params, this.references[c.reference]);
            }
          } else {
            return tempRenderCell(params, this.references[c.reference]);
          }
        };
      }

      if (c.Field == "isbn") {
        c.flex = 1.3;
        config["renderCell"] = (params) => {
          const value = params.value;
          if (!value) {
            return <div key={key}>-</div>;
          }

          return (
            <Tooltip title={value} key={key}>
              <div
                onClick={() => this.setState({ isbn: value })}
                style={{ cursor: "pointer" }}
              >
                {value}
              </div>
            </Tooltip>
          );
        };
      }

      if (
        c.hasOwnProperty("expandable") &&
        c.expandable &&
        c.hasOwnProperty("expandableContent")
      ) {
        c.flex = 2;
        c.renderCell = (params) => {
          if (c.expandableContent(params.row) == null) {
            return <div>{params.value}</div>;
          }
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <NxIconButton onClick={() => this.handleExpand(params)}>
                {params.row.expanded ? <ExpandLess /> : <ExpandMore />}
              </NxIconButton>
              {params.row.expanded
                ? c.expandableContent(params.row)
                : params.value}
            </div>
          );
        };
      }
      return config;
    });
    if (global.UF.data_structure[this.props.table].additionalData) {
      const additionalData =
        global.UF.data_structure[this.props.table].additionalData;
      additionalData.forEach((objInConfig) => {
        let currentColumn = columns.find((col) => col.field == objInConfig);
        currentColumn.hide = true;
      });
      columns.push({
        headerName: "Additional Data",
        field: "additional_data",
        align: "center",
        flex: 1,
        sortable: false,
        width: "auto",
        renderCell: (params) => {
          if (params.row.notes !== null && params.row.notes.trim() !== "") {
            return (
              <Tooltip
                title={this.handleToolTipText(additionalData, params.row)}
              >
                <Notes />
              </Tooltip>
            );
          } else {
            return <div />;
          }
        },
      });
    }
    if (this.props.buttons && Object.keys(this.props.buttons).length) {
      columns.push({
        field: " ",
        headerName: "",
        align: "center",
        width: "max-content",
        sortable: false,
        maxWidth: 200,
        flex: 1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return <div>{this.renderButtons(params)}</div>;
        },
      });
    }
    if (this.props.expandableRows) {
      columns.forEach((column, index) => {
        column.renderCell = (...args) => {
          const expandableRows = args[0].row?.prices?.split(",");
          return (
            <div
              style={{ width: "100%" }}
              textAlign={index === 0 ? "center" : "right"}
            >
              <Box
                style={
                  args[0].id === this.state.expandableRowId
                    ? {
                        height: 52,
                        display: "flex",
                        alignItems: "center",
                      }
                    : {}
                }
                textAlign={index === 0 ? "center" : "right"}
                justifyContent={index === 0 ? "center" : "right"}
                borderBottom={
                  args[0].id === this.state.expandableRowId
                    ? "1px solid rgba(224, 224, 224, 1)"
                    : "none"
                }
              >
                {args[0].value}
              </Box>
              <Collapse
                orientation="vertical"
                in={args[0].id === this.state.expandableRowId}
              >
                {expandableRows.map((expandableRow, expandIndex) => {
                  return (
                    <Box
                      style={{
                        height: 52,
                        display: "flex",
                        alignItems: "center",
                      }}
                      justifyContent={index === 0 ? "center" : "right"}
                      borderBottom={
                        args[0].id === this.state.expandableRowId &&
                        expandIndex !== expandableRows.length - 1
                          ? "1px solid rgba(224, 224, 224, 1)"
                          : "none"
                      }
                    >
                      {expandableRow.split(":")[index]}
                    </Box>
                  );
                })}
              </Collapse>
            </div>
          );
        };
      });
      columns.unshift({
        field: " ",
        headerName: "",
        align: "center",
        width: "20",
        sortable: false,
        flex: 0.1,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <NxIconButton onClick={() => this.handleExpandRow(params)}>
              {params.row.id === this.state.expandableRowId ? (
                <ExpandMore />
              ) : (
                <KeyboardArrowRight />
              )}
            </NxIconButton>
          );
        },
      });
    }
    return columns;
  };
  handleExpand = (params) => {
    if (!params.row.hasOwnProperty("expanded")) {
      params.row.expanded = true;
      this.forceUpdate();
      return;
    }

    params.row.expanded = !params.row.expanded;
    this.forceUpdate();
  };

  handleExpandRow = (params) => {
    switch (this.props.table) {
      case "view_offer_preparation":
        this.setState({
          expandableRowId:
            this.state.expandableRowId !== params.row?.id ? params.row?.id : -1,
        });
        this.forceUpdate();
        break;
      default:
        break;
    }
  };

  handleCellClick = (params) => {
    const ds = global.UF.data_structure[this.props.table];
    const fieldName = params.field;
    const currentField = ds.fieldsArr.find((field) => field.Field == fieldName);
    if (currentField && currentField.hasOwnProperty("onClick")) {
      currentField.onClick(params.row);
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (
      this.props.router.location.pathname != nextProps.router.location.pathname
    ) {
      this.fetchData(nextProps.filters);
    }
    if (nextState.activeRows.length) {
      if (nextState.activeRows.length == 1) {
        nextProps.popOverButtons.edit = () => {
          return (
            <Grid style={{ justifyContent: "center", alignItems: "center" }}>
              <IconButton
                onClick={() => nextProps.onEdit(nextState.activeRows[0])}
                size={"large"}
              >
                <Edit fontSize={"large"} sx={{ color: "#2D5DFCB2" }} />
              </IconButton>
            </Grid>
          );
        };
      } else {
        if (nextProps.popOverButtons.edit) {
          delete nextProps.popOverButtons.edit;
        }
      }
    }

    if (this.config.length != nextProps.fields.length) {
      this.config = nextProps.config
        ? nextProps.config
        : nextProps.fields
        ? nextProps.fields.map((field) =>
            typeof field == "string"
              ? global.UF.data_structure[nextProps.table].fields[field]
              : field
          )
        : global.UF.data_structure[nextProps.table].fieldsArr;
    }
    return true;
  };

  fetchData = (filters = this.props.filters) => {
    if (this.state.isLoading == false) {
      this.setState({ isLoading: true }, () => {
        global.UF.setMaskVisibility(true);
      });
    }

    const qs = Object.assign(
      {
        page: this.state.page,
        size: this.state.pageSize,
      },
      filters ? filters : {}
    );

    if (this.props.customFiltering) {
      this.props.customFiltering(qs);
    }

    const ds = global.UF.dataProvider.datastructure[this.props.table];

    if (ds.fields.hasOwnProperty("event_id")) {
      qs["event_id"] = global.UF.event_id;
    }

    if (ds.fields.hasOwnProperty("award_id")) {
      qs["award_id"] = global.UF.award_id;
    }

    if (ds.hasOwnProperty("filters") && ds.filters) {
      Object.keys(ds.filters).forEach((filter) => {
        qs[filter] = ds.filters[filter];
      });
    }

    if (this.state.sortField.length > 0) {
      qs.order_by = this.state.sortField[0].field;
      qs.order = this.state.sortField[0].sort;
    }

    if (this.props.syncData) {
      this.props.syncData(this.fetchData, qs);
    }

    global.UF.dataProvider.get(this.props.table, qs, (data, totalItems) => {
      if (this.props.onDataItemsLoad) {
        this.props.onDataItemsLoad(data, () => {
          this.setState({ data, totalItems, isLoading: false }, () => {
            global.UF.setMaskVisibility(false);
          });
        });
      } else {
        this.setState({ data, totalItems, isLoading: false }, () => {
          global.UF.setMaskVisibility(false);
        });
      }
    });
  };

  handleSelectionModelChange = (rowsIds) => {
    if (this.props.handleSelectionModelChange) {
      this.props.handleSelectionModelChange(rowsIds);
      return;
    }
    // if (rows.length) {
    //   this.setState({ popoverActive: true, activeRows: rows });
    // } else {
    //   this.setState({ popoverActive: false, activeRows: rows });
    // }
  };

  componentWillReceiveProps = (props) => {
    if (props.lastUpdate != this.lastUpdate) {
      this.lastUpdate = props.lastUpdate;
      this.fetchReferences(true, props.filters);
    }
  };

  renderPopOver = () => {
    return (
      <Nx_Grid_Popover
        show={this.state.popoverActive}
        buttons={this.props.popOverButtons}
        rows={this.state.activeRows}
        fetchData={this.fetchData}
      />
    );
  };

  handleRowClick = (params, event) => {
    if (event.ignore) {
      return;
    }

    if (this.props.onRowClick) {
      this.props.onRowClick(params);
    }
  };

  render() {
    return (
      <>
        {this.state.popoverActive && this.renderPopOver()}
        <DataGrid
          sx={{
            "& .MuiDataGrid-cell": {
              lineHeight: "unset !important",
              maxHeight: "unset !important",
              whiteSpace: "normal !important",
              textAlign: "center !important",
            },
            "& .MuiDataGrid-row": {
              maxHeight: "unset !important",
            },
            "& .MuiDataGrid-row:hover": {
              cursor:
                this.props.onRowClick || this.props.rowDoubleClick
                  ? "pointer"
                  : "auto",
            },
          }}
          components={{
            LoadingOverlay: function Component(props) {
              return <LinearProgress />;
            },
          }}
          rows={this.state.data}
          rowHeight={
            this.props.rowHeight ? this.props.rowHeight : this.state.rowHeight
          }
          getRowId={(row) => {
            const table_structure = row["table_structure"];
            const id =
              table_structure.hasOwnProperty("id") && table_structure.id
                ? table_structure.id
                : "id";

            if (Array.isArray(id)) {
              const values = id
                .map((i) => {
                  return row[i];
                })
                .join("-");
              return values;
            }

            return row[id];
          }}
          loading={this.state.isLoading}
          columns={this.handleColumns()}
          rowCount={this.state.totalItems}
          page={this.state.page}
          pageSize={this.state.pageSize}
          paginationMode="server"
          onPageChange={(newPage) => {
            this.state.page = newPage;
            this.fetchData();
          }}
          onPageSizeChange={(newPageSize) => {
            this.state.pageSize = newPageSize;
            this.fetchData();
          }}
          rowReordering
          initialState={{
            sorting: {
              sortModel: global.UF.data_structure[this.props.table]["sorting"]
                ? global.UF.data_structure[this.props.table]["sorting"]
                : this.state.sortField,
            },
          }}
          onCellClick={this.handleCellClick}
          checkboxSelection={this.props.checkboxSelection}
          keepNonExistentRowsSelected
          disableSelectionOnClick
          onSelectionModelChange={(items) =>
            this.handleSelectionModelChange(items)
          }
          onRowClick={this.handleRowClick}
          onRowDoubleClick={
            this.props.rowDoubleClick
              ? (params) => this.props.rowDoubleClick(params)
              : () => {}
          }
          rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
          disableColumnMenu
          sortingMode="server"
          onSortModelChange={this.handleSortModelChange}
          className="nx_grid_wrapper"
        />
      </>
    );
  }
}

export default withRouter(Nx_Grid);
